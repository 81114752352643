import { useDispatch, useSelector } from "react-redux"
import CustomAvatar from "../../Avatar"
import Button from "../../Button"

import './styles.css'
import { useState } from "react"
import { openModal } from "../../../action/modal"
import { selectServiceProposal } from "../../../action/serviceProposals"

const PaperServiceProposals = ({serviceProposal}) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user.data)

    const [loadingButtonChat, setLoadingButtonChat] = useState(false)

    const statusLabel = {
        watingResponse: "Aguardando resposta do cliente",
        refused: "Recusada pelo cliente",
        approved: "Aprovada",
        in_progress: "Em andamento",
        scheduled: "Na fila"
    }

    const services = {
        bateria: "Carga de Bateria",
        chaveiro: "Chaveiro",
        combustivel: "Combustível",
        mecanico: "Mecânico",
        pneu: "Troca de Pneus",
        reboque: "Reboque"
    }

    const redirectToWhatsapp = () => {
        setLoadingButtonChat(true)
        const message = `Olá! Tudo bem? 😊 \n\nMe chamo *${user.name}*, trabalho na *${user.company.name}* e irei realizar o atendimento da sua solicitação feita no aplicativo Keeper Assistências.`
        const phone = serviceProposal.consumer.cellphone.replace(/[^0-9]/g, '')
        
        window.open(`https://wa.me/${phone}?text=${message}`, '_blank')
        setLoadingButtonChat(false)
    }

    const handleRedirectService = () => {
        dispatch(selectServiceProposal(serviceProposal))
        dispatch(openModal({modal: "redirectService", value: true}))
    }

    return (
        <div className="paper-service-proposal-container">
            <div className="paper-service-proposal-content">
                <div className="paper-service-status-content">
                    <span className={`paper-service-status ${["in_progress", "approved"].includes(serviceProposal.status) ? "success" : "waiting"}`}>{statusLabel[serviceProposal.status]}</span>
                    {serviceProposal.consumer &&
                        <div className="paper-service-avatar-content">
                            <CustomAvatar label={serviceProposal.consumer.name}/>
                            <div className="paper-service-consumer-content">
                                <label>{serviceProposal.consumer.name}</label>
                                <span>{serviceProposal.consumer.email}</span>
                            </div>
                        </div>
                    }
                </div>
                <div className="paper-service-value-content">
                    <span className="paper-service-proposal-service">{services[serviceProposal.service]}</span>
                    <label>Valor do serviço:</label>
                    <span>{Number(serviceProposal.priceProvider).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}</span>
                </div>
            </div>
            {["approved", "in_progress"].includes(serviceProposal.status) &&
                <div className="paper-service-proposal-button-content">
                    <div className="paper-service-proposal-hr"/>
                    {serviceProposal.status !== "in_progress" &&
                        <Button 
                            label="Redirecionar serviço"
                            onClick={handleRedirectService}
                        />
                    }
                    {serviceProposal.consumer.cellphone &&
                        <Button 
                            kind="secondary"
                            label="Conversar com o cliente"
                            loading={loadingButtonChat}
                            onClick={redirectToWhatsapp}
                            style={{marginTop: 8}}
                        />
                    }
                </div>
            }
        </div>
    )
}
export default PaperServiceProposals