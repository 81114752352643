import CustomAvatar from "../../Avatar"

import './styles.css'

const PaperProviderDense = ({
    active,
    provider,
    onClick
}) => {
    return (
        <button className={`paper-provider-dense-container ${active && "active"}`} onClick={onClick}>
            <CustomAvatar label={provider.name}/>
            <div className="paper-provider-dense-content">
                <span className="paper-provider-dense-title">{provider.name}</span>
                <span>{provider.email}</span>
            </div>
        </button>
    )
}

export default PaperProviderDense