import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Brand from '../../assets/brand/logo-branco.svg'
import Button from '../../components/Button'
import InputPassword from '../../components/Input/Password'
import InputText from '../../components/Input/Text'

import * as userService from '../../service/user'

import './styles.css'
import { updateUser } from '../../action/user'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import PaperUser from '../../components/Paper/User'
import { useAuth } from '../../hooks/useAuth'
import CustomAlert from '../../components/Alert'

const Auth = () => {
    const dispatch = useDispatch()
    const { signin } = useAuth()

    const user = useSelector(state => state.user.data)

    const [userStoreged, setUser, __] = useLocalStorage("user", null);
    const [loadingButton, setLoadingButton] = useState(false)
    const [checkUser, setCheckUser] = useState(false)
    const [activeAlert, setActiveAlert] = useState({
        show: false,
        message: ""
    })

    const [forms, setForms] = useState({
        user: "",
        password: "",
        identityCompany: "",
    })

    const [passwordForms, setPasswordForms] = useState({
        password: "",
        confirmPassword: ""
    })

    const [formsError, setFormError] = useState({
        user: {error: false, message: "Usuário inválido."},
        password: {error: false, message: "Senha inválida."},
        identityCompany: {error: false, message: "CPF/CNPJ inválido."}
    })

    const regex = {
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[,.;:/?~^´`'"!@#$%^&*])(?=.{8,})/,
    }

    const checkForms = () => {
        if (forms.identityCompany === "") {
            console.log("opa")
            setFormError({
                ...formsError,
                identityCompany: {...formsError.identityCompany, error: true}
            })
        } else if (forms.user === "") {
            setFormError({
                ...formsError,
                user: {...formsError.user, error: true}
            })
            return false
        } 
        return true
    }

    const checkPassword = () => {
        if (forms.password === "") {
            setFormError({
                ...formsError,
                password: {...formsError.password, error: true}
            })
            return false
        }
        return true
    }

    const checkNewPassword = () => {
        return regex.password.test(passwordForms.password)
    }

    const doubleCheckNewPassowrd = () => {
        return passwordForms.confirmPassword === passwordForms.password
    }

    const formattedAddress = (address) => {
        return `${address.street}, ${address.number}, ${address.district}, ${address.city} - ${address.zip_code}`
    }

    const handleGetLocation = async (address) => {
        const apiKey = "AIzaSyCBFaUHIxOMqWmpSBrrWaF3uJbNDPSeXKE"
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${formattedAddress(address)}&key=${apiKey}`
        const response = await fetch(new Request(url)).then((response) => response.json())
    
        if (response.status === "OK") {
            const result = response.results[0]
            const geometry = result.geometry
    
            return {
                lat: geometry.location.lat,
                lng: geometry.location.lng,
            }
        }
    }

    const handleSetForms = (column, value) => {
        if (formsError[column].error) {
            setFormError({
                ...formsError,
                [column]: {...formsError[column], error: false}
            })
        }

        setForms({
            ...forms,
            [column]: value
        })
    }

    const handleCheckUser = async () => {
        setLoadingButton(true)
        if (checkForms()) {
            console.log(forms)
            const response = await userService.getCompanyUser(forms)
            if (response) {
                const coords = await handleGetLocation(response.company.address)

                const newUser = {
                    ...response,
                    company: {
                        ...response.company,
                        coords: coords
                    }
                }

                dispatch(updateUser(newUser))
                setUser(newUser)
                setCheckUser(true)
            } else {
                setActiveAlert({
                    show: true,
                    message: "Usuário não encontrado."
                })
            }
        }
        setLoadingButton(false)
    }

    const submit = async () => {
        setLoadingButton(true)
        if (checkPassword()) {
            const response = await signin({
                ...forms,
                identityCompany: user.identityCompany
            })

            if (!response) {
                setActiveAlert({
                    show: true,
                    message: "Senha inválida."
                })
            }
        }
        setLoadingButton(false)
    }

    const createPassword = async () => {
        setLoadingButton(true)
        if (checkNewPassword() && doubleCheckNewPassowrd()) {
            const response = await userService.createPassword({
                uuid: user.uuid,
                password: passwordForms.password
            })

            if (response) {
                dispatch(updateUser(response))
            }
        }
        setLoadingButton(false)
    }

    useEffect(() => {
        if (userStoreged) {
            setCheckUser(true)

            setForms({
                ...forms,
                user: userStoreged.user,
                identityCompany: userStoreged.identityCompany
            })

            dispatch(updateUser(userStoreged))
        }
    }, [])

    useEffect(() => {
        if (Object.keys(user).length === 0 && checkUser) setCheckUser(false)
    }, [user])

    return (
        <div className='auth-container'>
            <div>
                <img alt="Keeper" src={Brand}/>
            </div>
            <div className='auth-content'>
                <div className='auth-header-content'>
                    <h4>Bem vindo à Keeper!</h4>
                    <h1>Entre em sua conta</h1>
                </div>
                <div className='auth-forms-content'>
                    {Object.keys(user).length > 0 ? (
                        <PaperUser user={user}/>
                    ) : (
                        <>
                            <InputText 
                                mask="document"
                                label="CPF/CNPJ da Empresa"
                                placeholder="Informe o cpf ou cnpj da empresa cadastrada"
                                value={forms.identityCompany}
                                error={formsError.identityCompany.error}
                                helperText={formsError.identityCompany.message}
                                onChange={(event) => handleSetForms("identityCompany", event.target.value)}
                            />
                            <InputText 
                                label="Usuário"
                                placeholder="Informe o usuário"
                                error={formsError.user.error}
                                helperText={formsError.user.message}
                                onChange={(event) => handleSetForms("user", event.target.value)}
                                style={{
                                    marginTop: 12
                                }}
                            />
                        </>
                    )}
                    {user.new ? (
                        <>
                            <InputPassword 
                                label="Cadastrar senha"
                                error={!checkNewPassword() && passwordForms.password !== ''}
                                helperText={
                                    <ul>
                                        <li>A senha deve ter no mínimo 8 dígitos.</li>
                                        <li>Contendo uma letra maiúscula.</li>
                                        <li>Contendo uma letra minúscula.</li>
                                        <li>Contendo um número.</li>
                                        <li>Contendo um caractere especial.</li>
                                    </ul>
                                }
                                onChange={(event) => setPasswordForms({
                                    ...passwordForms,
                                    password: event.target.value
                                })}
                                style={{
                                    marginTop: 12
                                }}
                            />
                            <InputPassword 
                                label="Confirme a senha"
                                error={passwordForms.confirmPassword !== '' && !doubleCheckNewPassowrd()}
                                helperText="As duas senhas não são iguais."
                                onChange={(event) => setPasswordForms({
                                    ...passwordForms,
                                    confirmPassword: event.target.value
                                })}
                                style={{
                                    marginTop: 12
                                }}
                            />
                        </>
                    ) : checkUser &&
                        <InputPassword 
                            label="Senha"
                            error={formsError.password.error}
                            helperText={formsError.password.message}
                            onChange={(event) => handleSetForms("password", event.target.value)}
                            style={{
                                marginTop: 12
                            }}
                        />
                    }
                    {activeAlert.show &&
                        <CustomAlert 
                            show={activeAlert.show}
                            message={activeAlert.message}
                            onClose={() => setActiveAlert({
                                show: false,
                                message: ""
                            })}
                            style={{
                                marginTop: 8
                            }}
                        />
                    }
                </div>
                <div>
                    <Button 
                        label={user.new ? "Cadastrar senha" : !checkUser ? "Verificar conta" : "Acessar conta"}
                        loading={loadingButton}
                        onClick={user.new ? createPassword : !checkUser ? handleCheckUser : submit}
                    />
                    <Button 
                        kind="secondary" 
                        label="Precisando de ajuda?"
                        style={{
                            marginTop: 16
                        }}
                    />
                </div>
            </div>
            
        </div>
    )
}
export default Auth