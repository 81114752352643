import Layout from "../../../components/Layout"
import AccountTips from "./Accordin"

import * as userService from '../../../service/user'

import './styles.css'
import { useDispatch, useSelector } from "react-redux"
import { loadUsers, updateUser } from "../../../action/user"
import { useEffect, useState } from "react"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import PaperCompanyUser from "../../../components/Paper/CompanyUser"
import InputText from "../../../components/Input/Text"
import Button from "../../../components/Button"

const Access = () => {
    const dispatch = useDispatch()
    const [ userStoreged ] = useLocalStorage("user", null);

    const user = useSelector(state => state.user.data)
    const userList = useSelector(state => state.user.list)

    const [addUser, setAddUser] = useState(false)
    const [laodingAddUserButton, setLoadingAddUserButton] = useState(false)
    const [addUserForms, setAddUserForms] = useState({
        name: "",
        user: ""
    })

    const handleGetAllCompanyUsers = async () => {
        const response = await userService.getAllCompanyUsers(user.uuidCompany)

        if (response) {
            dispatch(loadUsers(response))
        }
    }

    const formatUserName = (value) => {
        return value.replace(/[^a-zA-Z0-9._ ]/g, '').replace(/ /g, '.')
    }

    const handleForms = (column, value) => {
        setAddUserForms({
            ...addUserForms,
            [column]: column === "user" ? formatUserName(value) : value
        })
    }

    const handleAddNewUser = async () => {
        setLoadingAddUserButton(true)
        const response = await userService.saveCompanyUser({
            ...addUserForms,
            photo: false,
            type: "employee",
            identityCompany: user.identityCompany,
            uuidCompany: user.uuidCompany
        })

        if (response) window.location.reload()
        setLoadingAddUserButton(false)
    }

    useEffect(() => {
        if (Object.keys(user).length > 0) handleGetAllCompanyUsers()
    }, [user])

    useEffect(() => {
        if (userStoreged) {
            dispatch(updateUser(userStoreged))
        }
    }, [])

    return (
        <Layout>
            <div className="access-container">
                <div className="access-content">
                    <div className="access-users-content">
                        <div style={{width: "100%"}}>
                            <h1>Gestão de acessos</h1>
                            <div className="access-users-list">
                                <div className="access-user-title-contet">
                                    <span className="access-users-title">Usuários:</span>
                                    <button onClick={() => setAddUser(true)}>Adicionar novo usuário</button>
                                </div>
                                {addUser &&
                                    <div className="access-users-forms-content">
                                        <div className="access-users-forms-item">
                                            <InputText 
                                                label="Qual o nome do novo usuário?"
                                                placeholder="Ex.: João Pedro"
                                                value={addUserForms.name}
                                                onChange={(event) => handleForms("name", event.target.value)}
                                            />
                                            <InputText 
                                                label="Qual o login do novo usuário?"
                                                placeholder="Ex.: joao.pedro"
                                                value={addUserForms.user}
                                                onChange={(event) => handleForms("user", String(event.target.value).toLocaleLowerCase())}
                                            />
                                        </div>
                                        <Button 
                                            loading={laodingAddUserButton}
                                            label="Adicionar usuário"
                                            onClick={handleAddNewUser}
                                        />
                                    </div>
                                }
                                <div className="access-users-grid">
                                    {userList.length > 0 && userList.map((item, index) => {
                                        if (item.type !== "manager") {
                                            return (
                                                <PaperCompanyUser key={index} companyUser={item}/>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="access-user-hr"/>
                    </div>
                    <div className="access-user-tips-content">
                        <div>
                            ⚠️ <strong> Dicas importantes de segurança</strong>
                            <p>
                                A segurança da sua conta é nossa prioridade. Para garantir a proteção dos seus dados e de toda a plataforma, 
                                pedimos que siga rigorosamente as orientações abaixo e <strong>nunca compartilhe sua senha</strong> com terceiros. 
                                A confidencialidade das suas credenciais é essencial para evitar acessos não autorizados e proteger informações sensíveis.
                            </p>
                            <p><strong>Passo a Passo para Ativação de Conta:</strong></p>
                            <ol>
                                <li>👤 <strong>Criação de Usuário:</strong> Para criar um usuário, será necessário informar o nome completo e o login correspondente. Por exemplo: Nome: João Pedro, Login: joao.pedro.</li>
                                <li>🔑 <strong>Criação de Senha:</strong> No primeiro acesso ao portal, João Pedro será solicitado a criar uma senha pessoal. É importante escolher uma senha forte e que não seja de fácil dedução.</li>
                                <li>🛠️ <strong>Alteração de Senha:</strong> Caso precise alterar sua senha, o usuário deverá entrar em contato com o administrador da conta, que irá proceder com a mudança.</li>
                                <li>🚫 <strong>Exclusão de Conta:</strong> Somente o administrador da conta possui a permissão para excluir um usuário da plataforma.</li>
                            </ol>
                            <p>Lembre-se: o compartilhamento de senhas é estritamente proibido. Mantenha suas credenciais seguras e, em caso de suspeita de comprometimento, informe imediatamente o administrador da conta.</p>

                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Access