import { useState } from 'react'

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const InputPassword = ({
    label,
    value,
    onChange,
    onBlur,
    error,
    helperText,
    style
}) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div style={style}>
            <TextField 
                error={error}
                type={showPassword ? 'text' : 'password'}
                size="small"
                label={label}
                variant="outlined" 
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                style={{width: "100%"}}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff style={{color: "var(--gray)"}}/> : <Visibility style={{color: "var(--gray)"}}/>}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            {error && <span style={{fontSize: 10, color: "#d32f2f"}}>{helperText}</span>}
        </div>
    )
}

export default InputPassword;