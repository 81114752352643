import api from "./api"

export const getSolicitation = async (uuidSolicitation) => {
    try {
        const response = await api.get(`/solicitation?id=${uuidSolicitation}`)
        if (response.status === 200) return response.data
        return false
    } catch (error) {
        console.log('getSolicitation',error)
    }
}