import { createContext, useContext, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { useLocalStorage } from "./useLocalStorage"
import { useDispatch } from "react-redux"

import * as userService from '../service/user'
import { updateUser } from "../action/user"

export const useAuth = () => {
    const [ user, setUser ] = useLocalStorage("user", null);
    const [ token, setToken ] = useLocalStorage("token", null);
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const signin = async (data) => {
        const response = await userService.signin(data)

        if (response) {
            setToken(response);
            navigate("/")
            return true
        } else {
            return false
        }
    };

    const signout = () => {
        setUser(null);
        setToken(null)
        dispatch(updateUser({}))
        navigate("/auth", { replace: true });
    };

    return {
        user: user,
        token: token,
        signin,
        signout
    }
}