import { Routes, Route } from "react-router-dom"
import { ProtectedRoute } from './Protected'

import Auth from '../pages/Auth'
import Home from '../pages/App/Home'
import Access from "../pages/App/Access"

const Router = () => {
    return (
        <Routes>
            <Route path="/auth" element={<Auth />}/>
            <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>}/>
            <Route path="/acessos" element={<ProtectedRoute><Access /></ProtectedRoute>}/>
        </Routes>
    )
}

export default Router;