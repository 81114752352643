import api from "./api"

export const getServiceProposalsByCompany = async (uuidCompany) => {
    try {
        const response = await api.get("/serviceProposals/getByCompany", {
            params: {
                uuidCompany: uuidCompany
            }
        })

        if (response.status === 200) return response.data
        return false
    } catch (error) {
        console.log("getServiceProposalsByCompany", error)
    }
}

export const redirectServiceProposal = async (payload) => {
    try {
        const response = await api.post('/serviceProposals/redirect', payload)
        if (response.status === 200) return response.data
        return false
    } catch (error) {
        console.log("redirectServiceProposal", error)
    }
}

export const calculateDistance = async (origins, destinations) => {
    try {
        const response = await api.get("/serviceProposals/calculateDistance", {
            params: {
                origins: origins,
                destinations: destinations
            }
        })

        if (response.status === 200) return response.data
        return false
    } catch (error) {
        console.log("calculateDistance", error)
    }
}