import { createSlice } from '@reduxjs/toolkit'

export const serviceProposalsSlice = createSlice({
    name: "serviceProposals",
    initialState: {
        selected: {},
        list: []
    },
    reducers: {
        loadServiceProposals: (state, action) => {
            state.list = action.payload
        },
        setServiceProposal: (state, action) => {
            state.list.push(action.payload)
        },
        selectServiceProposal: (state, action) => {
            state.selected = action.payload
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    setServiceProposal,
    loadServiceProposals,
    selectServiceProposal
} = serviceProposalsSlice.actions

export default serviceProposalsSlice.reducer