import api from "./api"

export const getCompanyUser = async (user) => {
    try {
        const response = await api.get('/user/userCompany', { params: user})
        if (response.status === 200) return response.data
        return false
    } catch (error) {
        console.log("getCompanyUser", error)
    }
}

export const signin = async (payload) => {
    try {
        const response = await api.post('/signin/companyUser', payload)
        if (response.status === 200) return response.data
        return false
    } catch (error) {
        console.log("signin", error)
    }
}

export const getAllCompanyUsers = async (uuidCompany) => {
    try {
        const response = await api.get('/user/allCompanyUsers', {
            params: {
                uuidCompany: uuidCompany
            }
        })

        if (response.status === 200) return response.data
        return false
    } catch (error) {
        console.log("getAllCompanyUsers", error)
    }
}

export const saveCompanyUser = async (user) => {
    try {
        const response = await api.post('/user/userCompany', user)
        return response.status === 200
    } catch (error) {
        console.log("saveCompanyUser", error)
    }
}

export const deleteComapnyUser = async (user) => {
    try {
        const response = await api.delete('/user/userCompany', {data: user})
        return response.status === 200
    } catch (error) {
        console.log("deleteComapnyUser", error)
    }
}

export const updateCompanyUser = async (user) => {
    try {
        const response = await api.put('/user/userCompany', user)
        return response.status === 200
    } catch (error) {
        console.log("deleteComapnyUser", error)
    }
}

export const createPassword = async (newPassword) => {
    try {
        const response = await api.post('/user/createPasswordCompanyUser', newPassword)
        if (response.status === 200) return response.data
        return false
    } catch (error) {
        console.log("createPassword", error)
    }
}