import { createSlice } from '@reduxjs/toolkit'

const detectDevice = () => {
    const userAgent = navigator.userAgent;
    const isMobileDevice = /Mobi|Android/i.test(userAgent)
    const isTabletDevice = /Tablet|iPad/i.test(userAgent)
  
    if (window.innerWidth <= 768 && isMobileDevice) return "mobile"
    else if (window.innerWidth > 768 && window.innerWidth <= 1024 && isTabletDevice) return "tablet"
    return "desktop"
}
  

export const userSlice = createSlice({
    name: "user",
    initialState: {
        data: {},
        list: []
    },
    reducers: {
        updateUser: (state, action) => {
            const device = detectDevice()

            state.data = action.payload
            state.device = device
        },
        loadUsers: (state, aciton) => {
            state.list = aciton.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { 
    updateUser,
    loadUsers
} = userSlice.actions

export default userSlice.reducer