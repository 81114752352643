import { configureStore } from '@reduxjs/toolkit'

import userSlice from './action/user'
import modalSlice from './action/modal'
import providersSlice from './action/providers'
import solicitationsSlice from './action/solicitations'
import webSocketSlice from './action/webScocket'
import serviceProposalsSlice from './action/serviceProposals'

export default configureStore({
  reducer: {
    user: userSlice,
    modal: modalSlice,
    providers: providersSlice,
    webSocket: webSocketSlice,
    solicitations: solicitationsSlice,
    serviceProposals: serviceProposalsSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck: false
    }),
})