import CustomAvatar from "../../Avatar"
import IconButton from '@mui/material/IconButton';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

import './styles.css'
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../action/user";

const PaperUser = ({
    user
}) => {
    const dispatch = useDispatch()
    const [ _, __, deleteUser ] = useLocalStorage("user", null);

    const userTypeLabe = {
        manager: "Gestor",
        employee: "Colaborador",
        admin: "Administrador"
    }

    const changeUser = () => {
        dispatch(updateUser({}))
        deleteUser("user")
    }

    return (
        <div className="paper-user-container">
            <CustomAvatar label={user.name} />
            <div className='paper-user-content'>
                <div className="paper-user-info">
                    <label>{`${user.name} - ${user.company.name}`}</label>
                    <span>{user.new ? "Novo usuário" : userTypeLabe[user.type]}</span>
                </div>
                <div>
                    <IconButton onClick={changeUser}>
                        <DriveFileRenameOutlineIcon style={{color: "var(--blue)"}}/>
                    </IconButton>
                </div>
            </div>
        </div>
    )
}

export default PaperUser