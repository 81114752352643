import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateProvider } from "../action/providers";
import { setNewSolicitation } from "../action/solicitations";
import { setWebSocketConnection, setWebSocketReady } from "../action/webScocket";

import * as serviceProposalService from '../service/serviceProposals'
import { loadServiceProposals, setServiceProposal } from "../action/serviceProposals";

export const useWebSocket = () => {
    const dispatch = useDispatch()

    const pingIntervalRef = useRef(null)
    const notificationSound = useRef(null)
    const chashierNotificationSound = useRef(null)

    useEffect(() => {
        notificationSound.current = new Audio('/sounds/notification.mp3');
        chashierNotificationSound.current = new Audio('/sounds/cashier.mp3');
        if (Notification.permission !== "granted") {
            Notification.requestPermission();
        }

        return () => {
            if (pingIntervalRef.current) {
                clearInterval(pingIntervalRef.current);
            }
        }
    }, []);

    const setConfig = (wss, uuidUser, uuidCompany) => {
        const message = {
            action: "setConfig",
            uuidUser: uuidUser,
            config: {
                uuidCompany: uuidCompany
            },
        }
    
        wss.send(JSON.stringify(message));
    }

    const showNotification = (title, body, action) => {
        if (Notification.permission === "granted") {
            new Notification(title, { body });

            if (action === "newSolicitation") notificationSound.current.play().catch(e => console.log('Error playing sound:', e));
            else chashierNotificationSound.current.play().catch(e => console.log('Error playing sound:', e));
        }
    };

    const handleLoadServiceProposals = async (uuidCompany) => {
        const response = await serviceProposalService.getServiceProposalsByCompany(uuidCompany)
        if (response) {
            dispatch(loadServiceProposals(response))
        }
    }

    const startWebSocket = (uuidUser, uuidCompany) => {
        const ws = new WebSocket(`${process.env.REACT_APP_BASE_URL_WSS}?uuidUser=${uuidUser}&uuidCompany=${uuidCompany}&accessType=company`);
        
        ws.onopen = async () => {
            setConfig(ws, uuidUser, uuidCompany)
            dispatch(setWebSocketReady(true))

            // Inicia o envio do "ping" a cada 60 segundos
            pingIntervalRef.current = setInterval(() => {
                if (ws.readyState === WebSocket.OPEN) {
                    ws.send(JSON.stringify({ action: 'keepConnection' }))
                }
            }, 60000); // 60 segundos
        }

        ws.onmessage = async (event) => {
            const data = JSON.parse(event.data);

            if (data.action === "sendLocation") {
                dispatch(updateProvider(data));
            }

            if (data.action === "newSolicitation") {
                dispatch(setNewSolicitation(data.solicitation))
                showNotification("Nova Solicitação", "Você tem uma nova solicitação!", data.action);
            }

            if (data.action === "approveServiceProposal") {
                handleLoadServiceProposals(data.solicitation.uuidCompany)
                showNotification("Proposta Aprovada", "Sua proposta foi aprovada. Direcione um prestador para atender o cliente!", data.action);
            }

            if (data.action === "sendServiceProposals") {
                dispatch(setServiceProposal(data.serviceProposal))
            }
        }

        ws.onclose = () => {
            if (pingIntervalRef.current) {
                clearInterval(pingIntervalRef.current); // Limpa o intervalo quando a conexão é fechada
            }
            dispatch(setWebSocketReady(false));
        }

        dispatch(setWebSocketConnection(ws))
    }

    return {
        startWebSocket,
    }
}
