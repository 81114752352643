import { useSelector } from 'react-redux'
import { useAuth } from '../../../hooks/useAuth'
import './styles.css'

const Header = () => {
    const { signout } = useAuth()

    const user = useSelector(state => state.user.data)
    const device = useSelector(state => state.user.device)

    return (
        <div className="header-container">
            <div className="header-content">
                {user.name && <span className="header-title">{device !== "mobile" ? `Seja bem-vindo ${user.name}!! 😊`: "Seja bem-vindo!! 😊"}</span>}
                <button className="header-button" onClick={signout}>Sair</button>
            </div>
        </div>
    )
}
export default Header