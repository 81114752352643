import {
    FormGroup,
    FormControlLabel,
    Switch
} from '@mui/material';

const CustomSwitch = ({
    label,
    checked,
    onChange,
    disabled,
    labelOrientation,
}) => {
    return (
        <FormGroup>
            <FormControlLabel 
                label={label}
                labelPlacement={labelOrientation}
                control={<Switch disabled={disabled} checked={checked} onChange={onChange}/>} 
            />
        </FormGroup>
    )
}

export default CustomSwitch