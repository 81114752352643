import { createSlice } from '@reduxjs/toolkit'

export const solicitationsSlice = createSlice({
    name: "solicitations",
    initialState: {
        selected: {},
        newSolicitation: {},
        activeSolicitations: []
    },
    reducers: {
        setNewSolicitation: (state, action) => {
            state.newSolicitation = action.payload
        },
        selectSolicitation: (state, action) => {
            state.selected = action.payload
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    setNewSolicitation,
    selectSolicitation
} = solicitationsSlice.actions

export default solicitationsSlice.reducer