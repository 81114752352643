import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import * as serviceProposalService from '../../../service/serviceProposals'

import { openModal } from "../../../action/modal"
import { selectProvider } from "../../../action/providers"

import { Modal } from "@mui/material"
import PaperProviderDense from "../../Paper/ProviderDense"
import Button from "../../Button"

import './styles.css'

const RedirectServiceModal = () => {
    const dispatch = useDispatch()

    const user = useSelector(state => state.user.data)
    const modalActive = useSelector(state => state.modal.redirectService)
    const providerSelected = useSelector(state => state.providers.selected)
    const providers = useSelector(state => Object.values(state.providers.list))
    const serviceProposal = useSelector(state => state.serviceProposals.selected)

    const [loadingButotn, setLoadingButton] = useState(false)

    const handleClose = () => {
        dispatch(openModal({modal: "redirectService", value: false}))
    }

    const handleRedirectSolicitation = async () => {
        setLoadingButton(true)
        const response = await serviceProposalService.redirectServiceProposal({
            serviceProposal: serviceProposal,
            provider: providerSelected,
            company: {
                ...user.company,
                uuid: user.uuidCompany
            }
        })

        if (response) {
            window.location.reload()
        }
        setLoadingButton(false)
    }

    return (
        <Modal
            open={modalActive}
            onClose={handleClose}
        >
            <div className="modal-redirect-service-container">
                <div className="modal-redirect-service-title-content">
                    <h2>Redirecionar Serviço</h2>
                </div>
                <div className="modal-redirect-service-content">
                    <div className="modal-redirect-service-title">
                        <span>Prestadores online:</span>
                        {providers.length > 0 && 
                            <div className="modal-redirect-service-providers-grid">
                                {providers.map((provider, index) => {
                                    return (
                                        <PaperProviderDense 
                                            key={index}
                                            provider={provider} 
                                            active={providerSelected.uuid === provider.uuid}
                                            onClick={() => dispatch(selectProvider(provider))}
                                        />
                                    )
                                })}
                            </div>
                        }
                    </div>
                    <div className="paper-solicitation-summury-footer">
                        <Button 
                            loading={loadingButotn}
                            disabled={Object.keys(providerSelected).length === 0}
                            label={"Redirecionar"}
                            style={{width: "100%", marginTop: 16}}
                            onClick={handleRedirectSolicitation}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default RedirectServiceModal
