import  { 
    AdvancedMarker, 
    InfoWindow,
    useAdvancedMarkerRef
} from '@vis.gl/react-google-maps'

import PointCarIcon from '../../assets/icon/pointer-car.svg';
import PointTruckIcon from '../../assets/icon/pointer-truck.svg';
import PointGarageIcon from '../../assets/icon/pointer-garage.svg';
import PointMarioIcon from '../../assets/icon/pointer-mario.svg';
import { useState } from 'react';
import PaperProvider from '../Paper/Provider';
import { useDispatch } from 'react-redux';
import { selectProvider } from '../../action/providers';

export const Marker = ({
    provider,
    position,
    icon
}) => {
    const dispatch = useDispatch()
    const [markerRef, marker] = useAdvancedMarkerRef();
    const [infowindowShown, setInfowindowShown] = useState(false);

    const icons = {
        carro: PointCarIcon,
        reboque: PointTruckIcon,
        oficina: PointGarageIcon,
        mecanico: PointMarioIcon
    }

    const toggleInfoWindow = () => {
        dispatch(selectProvider(provider))
        setInfowindowShown(previousState => !previousState)
    }

    const closeInfoWindow = () => {
        setInfowindowShown(false)
    }

    return (
        <AdvancedMarker 
            ref={markerRef}
            draggable={false} 
            position={position}
            onClick={toggleInfoWindow}
        >
            <img alt="marker" src={icons[icon]}/>
            {provider && infowindowShown && 
                <InfoWindow anchor={marker} onCloseClick={closeInfoWindow}>
                    <PaperProvider provider={provider}/>
                </InfoWindow>
            }
        </AdvancedMarker>
    )
}