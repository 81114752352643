import { IconButton } from "@mui/material"

import DeleteIcon from '@mui/icons-material/Delete';

import CustomAvatar from "../../Avatar"
import CustomSwitch from "../../Input/Switch";

import * as userService from '../../../service/user'

import './styles.css'
import { useState } from "react";

const PaperCompanyUser = ({companyUser}) => {
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [loadingUpdate, setLoadingUpdate] = useState(false)

    const handleDeleteUser = async () => {
        setLoadingDelete(true)
        const response = await userService.deleteComapnyUser(companyUser)
        if(response) window.location.reload()
        setLoadingDelete(false)
    }

    const handleUpateType = async (event) => {
        setLoadingUpdate(true)
        const isAdmin = event.target.checked

        const response = await userService.updateCompanyUser({
            ...companyUser,
            type: isAdmin ? "admin" : "employee"
        })

        if (response) window.location.reload()

        setLoadingUpdate(false)
    }

    return (
        <div className="paper-company-user-container">
            <CustomAvatar label={companyUser.name}/>
            <div className="paper-comapny-user-content">
                <div className="paper-company-user-data">
                    <label>{companyUser.name}</label>
                    <span>{companyUser.user}</span>
                </div>
                <div>
                    {!["manager", "admin"].includes(companyUser.type) ? (
                        <CustomSwitch 
                            disabled={loadingUpdate}
                            label="Tornar Admin?" 
                            labelOrientation="start"
                            onChange={handleUpateType}
                        />
                    ) : companyUser.type === "admin" ? (
                        <span>Admin</span>
                    ) : (
                        <span>Gerente</span>
                    )}
                </div>
                <div>
                    <IconButton disabled={loadingDelete || companyUser.type === "manager"} onClick={handleDeleteUser}>
                        <DeleteIcon style={{color: 'var(--blue)'}}/>
                    </IconButton>
                </div>
            </div>
        </div>
    )
}

export default PaperCompanyUser