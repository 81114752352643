import { Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useLocalStorage } from "../../hooks/useLocalStorage";

export const ProtectedRoute = ({ children }) => {
    const { token } = useAuth();
    const [ user ] = useLocalStorage("user", null)

    const pathName = window.location.pathname
    
    if (!token) return <Navigate to="/auth" />
    else if (user.type === "employee" && pathName === "/acessos") return <Navigate to="/" />
    return children;
};