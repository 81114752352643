import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import Brand from '../../../assets/brand/logo-branco.png'

import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import IconButton from '@mui/material/IconButton';

import './styles.css'

const Sidebar = () => {
    const navigate = useNavigate()
    const pathName = window.location.pathname

    const user = useSelector(state => state.user.data)

    const menu = [
        {id: "home", path: "/", label: "Início", icon: <HomeIcon/>},
        {id: "access", path: "/acessos", label: "Acesso", icon: <GroupIcon/>},
    ]

    const handleActiveContent = (pathItem) => {
        if (pathName === "/" && pathName === pathItem) {
            return "active"
        } else if (pathItem !== "/" && pathName.includes(pathItem)) {
            return "active"
        }
        return "inactive"
    }

    return (
        <div className="sidebar-container">
            <img src={Brand} alt="keeper"/>
            <div className='sidebar-content'>
                {menu.map((item, index) => {
                    if (item.id === "access" && user.type === "employee") return

                    return (
                        <IconButton 
                            key={index} 
                            onClick={() => navigate(item.path)}
                            className={`sidebar-button ${handleActiveContent(item.path)}`}
                        >
                            <div className={`sidebar-icon-button ${handleActiveContent(item.path)}`}>
                                {item.icon}
                            </div>
                            <span>{item.label}</span>
                        </IconButton>
                    )
                })}
            </div>
        </div>
    )
}
export default Sidebar