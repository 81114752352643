import { useSelector } from "react-redux"
import Header from "./Header"
import Sidebar from "./Sidebar"

import './styles.css'

const Layout = ({children}) => {
    const device = useSelector(state => state.user.device)

    return (
        <div className="layout-conteiner">
            {device !== "mobile" && <Sidebar />}
            <div className="layout-content">
                <Header />
                {children}
            </div>
        </div>
    )
}

export default Layout